<template>
    <v-main>
        <router-view />
    </v-main>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Home',
    components: {

    }
}
</script>
