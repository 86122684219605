<template>
    <v-main>
        <v-progress-linear :value="progress" color="primary"></v-progress-linear>
        <v-container>
            
            <v-img lazy-src="~@/assets/logowhite.png" max-height="200" max-width="200" src="~@/assets/logowhite.png" class="mx-auto" to="/"></v-img>
            <v-stepper v-model="step" color="primary" flat>
                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card class="mt-12" color="transparent" flat height="300px">
                            <h2 class="text-bold text-primary">What's your Name?*</h2>
                            <h5 class="text-normal">Please provide your full name for verification purposes.</h5>
                            <v-text-field type="text" color="primary" v-model="form.name" counter="50"
                                hint="Please provide your full name." label="Type your full name here"></v-text-field>
                        </v-card>
                        <v-btn color="primary" @click="step = 2; progress = 25">
                            Continue
                        </v-btn>
                        <v-btn text to="/">
                            Go back
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-card color="transparent" flat class="mt-12" height="300px">
                            <h2 class="text-bold text-primary">How can we contact you via Email?*</h2>
                            <h5 class="text-normal">Ensure you enter a valid email address; this will be our primary mode of
                                communication.</h5>
                            <v-text-field type="email" color="primary" v-model="form.email" counter="50"
                                hint="Please provide your e-mailadress for communication."
                                label="Type your e-mailadress here"></v-text-field>
                        </v-card>
                        <v-btn color="primary" @click="step = 3; progress = 50">
                            Continue
                        </v-btn>
                        <v-btn text @click="step = 1; progress = 25">
                            Go back
                        </v-btn>
                    </v-stepper-content>
                    <v-stepper-content step="3">
                        <v-card color="transparent" flat class="mt-12" height="300px">
                            <h2 class="text-bold text-primary">What's your Telegram Handle?</h2>
                            <h5 class="text-normal">Telegram is our secondary mode of communication. Kindly provide your
                                handle starting with '@'.</h5>
                            <v-text-field type="text" color="primary" v-model="form.telegram" counter="60"
                                hint="Please your Telegram handle starting with an @."
                                label="Type your Telegram handle here"></v-text-field>
                        </v-card>
                        <v-btn color="primary" @click="step = 4; progress = 75">
                            Continue
                        </v-btn>
                        <v-btn text @click="step = 2; progress = 50">
                            Go back
                        </v-btn>
                    </v-stepper-content>
                    <v-stepper-content step="4">

                        <v-card color="transparent" flat class="my-12">
                        <paymentPrivateSale :form="form" v-if="payment" />
                        </v-card>
                        <v-btn text @click="step = 3; progress = 75">
                            Go back
                        </v-btn>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-container>

    </v-main>
</template>
<style scoped>
.theme--dark.v-stepper {
    background-color: transparent !important;
}
</style>
<script>
import paymentPrivateSale from "../components/payment.vue"

export default {
    components: {
        paymentPrivateSale,
    },
    data: () => ({
        form: {
            name: "",
            email: "",
            telegram: "",
            amount: "",
            wallet: "",
            txn: ""
        },
        step: 1,
        progress: 0,
        payment: true
    }),

}
</script>