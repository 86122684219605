<template>
    <v-app>

        <v-main class="background">
            <formPrivateSale />
           
        </v-main>
    </v-app>
</template>

<script>
import formPrivateSale from "../components/form.vue"

export default {
    components: {
        formPrivateSale
    },
    data: () => ({
        progress: 10,
        raised: 0,
        symbol: "ETH",
        hardcap: 0,
        softcap: 0
    }),

}
</script>
<style>
.v-btn.primary {
    font-weight: 900 !important;
    font-family: 'Archivo', sans-serif !important;
    padding: 10px 20px !important;
    color: rgba(33, 33, 33, 1) !important;
}
</style>
