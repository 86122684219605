<template>
    <v-main>
        <div v-if="!connected">
            <v-row>
                <v-col cols="12">
                    <v-alert dense outlined type="error" v-if="warning != ''">
                        {{ warning }}
                    </v-alert>
                    <h1>Connect wallet</h1>
                    <p class="grey--text mt-5">Click the button below to connect your wallet</p>
                    <v-btn @click="connectWalletLogin" color="primary" x-large>Connect your wallet</v-btn>
                </v-col>
            </v-row>
        </div>
        <div v-else>
            <v-row v-if="status == null">
                <v-col cols="12">
                    <v-alert dense outlined type="warning" v-if="warning != ''">
                        <strong>{{ warning }}</strong>
                    </v-alert>
                    <v-alert dense outlined type="error" v-if="error != ''">
                        <strong>{{ error }}</strong>
                    </v-alert>
                    <h2>$MAMOT PRIVATE PRESALE SALE</h2>
                </v-col>
                <v-col cols="12">
                    <v-text-field type="number" v-model="amount" :rules="[numberRule]" suffix="ETH"
                        label="Amount to invest">
                        <v-btn color="primary" slot="append" min="0.1" max="2" class="ml-5 rounded-l-0"
                            @click="amount = 2">MAX</v-btn>
                    </v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-btn-toggle color="primary" v-model="amount" borderless>
                        <v-btn value="0.1" x-large>
                            <span>0.1 ETH</span>
                        </v-btn>

                        <v-btn value="0.5" x-large>
                            <span >0.5 ETH</span>
                        </v-btn>

                        <v-btn value="1" x-large>
                            <span >1 ETH</span>
                        </v-btn>

                        <v-btn value="2" x-large>
                            <span>2 ETH</span>
                        </v-btn>
                    </v-btn-toggle>
                </v-col>
                <v-col cols="12">
                    <v-divider class="my-5"></v-divider>
                    <v-btn color="primary" :disabled="loading || warning != ''" @click="privateSaleBuy" block class="mb-5"
                        x-large>
                        Buy
                    </v-btn>
                    <v-progress-linear indeterminate color="primary" v-if="loading" class="mt-5" height="25">
                        Waiting for the transaction to confirm</v-progress-linear>
                </v-col>
                <v-col cols="12">
                    <p class="grey--text overline">Having troubles? Please contact @Cryptostr1k3rs on Telegram</p>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col v-if="status == 'success'" cols="col-12">
                    <h1 class="text-center">Transaction succesfull</h1>
                    <p class="text-center">Thankyou for joining our project.</p>
                    <p class="text-center">Txn: {{ transactionHash }}</p>
                </v-col>
                <v-col v-else cols="col-12">
                    <h1 class="text-center">Transaction failed</h1>
                    <v-alert dense outlined type="error" class="my-12">
                        Sorry for the inconvinience, Something went wrong. Please contact our team via telegram.
                    </v-alert>

                    <v-btn color="primary" @click="loading = false; status = null;" x-large>Try again</v-btn>

                </v-col>
            </v-row>

        </div>


    </v-main>
</template>


<script type="module">

import Onboard from '@web3-onboard/core'
import injectedModule from '@web3-onboard/injected-wallets'
import walletConnectModule from '@web3-onboard/walletconnect'
import axios from 'axios';
import { ethers } from 'ethers'
/* global BigInt */
export default {
    props: {
        form: Object,
    },
    data: () => ({
        MAINNET_RPC_URL: "https://mainnet.infura.io/v3/29b0b29649e946b68d0aa7199b3ebd40",
        connected: false,
        status: null,
        loading: false,
        warning: "",
        error: "",
        amount: 0.1,
        injected: null,
        onboard: null,
        transactionHash: null,
        wallets: null,
        numberRule: v => {
            if (!isNaN(parseFloat(v)) && v >= 0.1 && v <= 2) return true;
            if (!isNaN(parseFloat(v)) && v > 2) return 'Maximum amount to invest is 2 ETH';
            if (!isNaN(parseFloat(v)) && v < 0.1) return 'Minimum amount to invest is 0.1 ETH';
        },
    }),
    methods: {
        async connectWalletLogin() {
            this.wallets = await this.onboard.connectWallet()
            this.connected = true;
            const ethersProvider = new ethers.providers.Web3Provider(this.wallets[0].provider, 'any')
            const chain = await ethersProvider.getNetwork();
            console.log(chain.chainId);
            if (chain.chainId != 1) {
                this.warning = "Wrong Network: Please change network to Ethereum Mainnet";
            }
        },
        async privateSaleBuy() {

            if (this.wallets[0]) {
                if (this.amount >= 0.1 && this.amount <= 2) {
                    this.error = "Amount to invest has to be between 0.1 and 2 ETH.";
                } else {
                    this.error = "";
                }
                // create an ethers provider with the last connected wallet provider
                const ethersProvider = new ethers.providers.Web3Provider(this.wallets[0].provider, 'any')

                const signer = ethersProvider.getSigner()

                // send a transaction with the ethers provider
                const txn = await signer.sendTransaction({
                    to: "0xb39a141F2932e95ecB8A638Cc5134606c43a67FF",
                    value: BigInt(this.etherToWei(this.amount))
                })
                this.loading = true;
                const receipt = await txn.wait()
                this.loading = false;
                this.status = receipt.status == 1 ? "success" : "failed";
                this.transactionHash = receipt.transactionHash

                if (this.status == "success") {
                    this.form.amount = this.amount;
                    this.form.wallet = this.wallets[0].accounts[0].address;
                    this.form.txn = this.transactionHash;
                    axios.post("https://api.crypto-strikers.com/v1/transaction", this.form).then((res) => console.log(res.data))

                }
            }
        },
        etherToWei(amountToSend) {
            const etherValue = parseFloat(amountToSend);
            const weiValue = Math.floor(etherValue * 1e18);
            return Number(weiValue);
        }


    },
    mounted() {

        this.injected = injectedModule()
        const wcV2InitOptions = {
            projectId: '06af66829ff6bb31f231ae71046107b9',
            requiredChains: [1],
            dappUrl: 'https://privatesale.crypto-strikers.com'
        }

        // initialize the module with options
        // If version isn't set it will default to V2 - V1 support will be completely removed shortly as it is deprecated
        const walletConnect = walletConnectModule(wcV2InitOptions)

        this.onboard = Onboard({
            wallets: [walletConnect,this.injected],
            chains: [
                {
                    id: '0x1',
                    token: 'ETH',
                    label: 'Ethereum Mainnet',
                    rpcUrl: this.MAINNET_RPC_URL
                }
            ]
        })


    },


}
</script>